<template>
  <div :class="$style.main">
    <div :class="$style.container">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" module>
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-height: 100dvh;
  padding: 20px;
}

.container {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  padding: 36px;
  background: #fff;

  @include media($to: xs) {
    padding: 24px;
  }
}
</style>
